import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import { graphql } from 'gatsby'

import { Main } from '@leshen/ui'

import { mapSections } from '@leshen/gatsby-theme-contentful/utils/determiners'

import SpeedTestIframe from '../components/SpeedTestIframe'

const SpeedTest = ({ data }) => {
  const {
    contentfulPage: { sections, spanish },
  } = data

  const sectionOptions = {
    spanish,
  }

  return (
    <Layout
      data={data}
      main={
        <Main>
          <SpeedTestIframe />
          <div id="loadingScrollTarget" />
          {/* AvailablePlans will show the package cards based on what is available from the Availability form getting used from the Hero. */}
          {/* Render the rest of the sections from Contentful like normal */}
          {sections &&
            sections.map((section) => mapSections(section, sectionOptions))}
        </Main>
      }
    />
  )
}

SpeedTest.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default SpeedTest

export const query = graphql`
  query SpeedTest($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...LeshenPage
    }
    site {
      ...LeshenMetadata
    }
  }
`
