import React from 'react'
import * as Styled from './SpeedTest.styles'

const SpeedTestIframe = () => {
  return (
    <Styled.SpeedTestIframe
      frameBorder="0"
      src="https://getwindstreamcom.speedtestcustom.com"
      title="Speed Test"
    />
  )
}

export default SpeedTestIframe
